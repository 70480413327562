import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import parse from "html-react-parser"
// import GravityFormForm from "gatsby-plugin-gravity-forms"

const Page = ({ data: { page /*, wpGfForm*/ } }) => {
  const {
    databaseId,
    title,
    content,
    slug,
    formTemplate,
    featuredImage,
    smartcrawlSeo,
  } = page

  return (
    <Layout>
      <Seo
        title={smartcrawlSeo?.title}
        description={smartcrawlSeo?.metaDesc}
        ogTitle={smartcrawlSeo?.opengraphTitle}
        ogDescription={smartcrawlSeo?.opengraphDescription}
        twTitle={smartcrawlSeo?.twitterTitle}
        twDescription={smartcrawlSeo?.twitterDescription}
        featuredImage={featuredImage?.node?.mediaItemUrl}
        ogImage={smartcrawlSeo?.opengraphImage?.mediaItemUrl}
        twImage={smartcrawlSeo?.twitterImage?.mediaItemUrl}
      />

      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Organization",
              "url": "https://centerforinquiry.org/",
              "name": "Center for Inquiry ",
              "description": "The Center for Inquiry (CFI) is a 501(c)(3) charitable nonprofit organization dedicated to defending science and critical thinking in examining religion. CFI’s vision is a world in which evidence, science, and compassion—rather than superstition, pseudoscience, or prejudice—guide public policy.",
              "@id": "https://centerforinquiry.org/#organization/",
              "logo": "https://translationsproject.org/static/5b8e5d5a96fdd64b0bb44c7de1015d52/f2ee4/translations-project-logo-desktop-768x240.webp",
              "sameAs": [
                "https://www.facebook.com/centerforinquiry/",
                "https://twitter.com/center4inquiry"
              ],
              "subOrganization": [
                {
                  "@type": "Organization",
                  "@id": "https://translationsproject.org/#organization",
                  "name": "The Translations Project" 
                }
              ]
            }     
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "Translations Project",
              "@id": "https://translationsproject.org/#website",
              "url": "https://translationsproject.org/",
              "isAccessibleForFree": true,
              "sourceOrganization": 
                {
                  "@type": "Organization",
                  "@id": "https://centerforinquiry.org/#organization",
                  "name": " Center for Inquiry" 
              }
            }     
          `}
        </script>
      </Helmet>
      <article id={"post-" + databaseId} className={slug + " page hentry"}>
        <header className="entry-header">
          <h1 className="entry-title">{parse(title)}</h1>
        </header>
        <div className="entry-content">
          {parse(content)}
          {/* {formTemplate.formId && <GravityFormForm data={wpGfForm} />} */}
        </div>
      </article>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query GET_PAGE($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      databaseId
      uri
      slug
      # formTemplate {
      # formId
      # }
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
    # Commenting out wpGfForm part
    # wpGfForm(databaseId: { eq: $formId }) {
    #   ...GravityFormFields
    # }
  }
`
